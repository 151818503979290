import React from "react";
import CommonLayout from "./commonLayout";
import { Col, Container, Row } from "react-bootstrap";
import { GuestTeamTable } from "@/components/table/index";
import { useGetSingleSessions, useGetTeams } from "@/customHooks";
import { useParams } from "react-router-dom";
import { decrypt } from "@/utils/crypto";
import { Group } from "@/components";
import { WelcomeParticipants } from "../components/modal";

function GuestParticipants({ isLoggedIn }) {
  const { id } = useParams();
  const getTeam = useGetTeams(Number(decrypt(id)));
  const getSession = useGetSingleSessions(Number(decrypt(id)));
  const sessionData = getSession?.data || {};
  const session = sessionData?.session || {};

  let isGameComplete =
    getTeam?.data?.length &&
    getTeam?.data?.every((team) => team.is_game_complete === 1);
  return (
    <>
      <CommonLayout guestParticipants={true} />
      <Container className="mt-10">
        <Row className="gx-lg-20">
          {isGameComplete ? (
            <Group
              getTeam={getTeam}
              sessionData={session}
              isLoggedIn={isLoggedIn}
            />
          ) : (
            getTeam?.data?.map((item, index) => {
              return (
                <Col lg={6} key={index}>
                  <GuestTeamTable
                    teamData={item}
                    refetch={getTeam.refetch}
                    current_status={item?.current_status}
                    current_round={item?.current_round}
                  />
                </Col>
              );
            })
          )}
          {!getTeam?.data?.length && (
            <Col lg={6}>
              <GuestTeamTable />
            </Col>
          )}
        </Row>
      </Container>
      <WelcomeParticipants id={id} session={session} />
    </>
  );
}

export default GuestParticipants;
