import { React, useState, useEffect } from 'react';
import { Modal, Button, FormControl } from 'react-bootstrap';
import { useFormik } from 'formik';
import { addParticipantValidate } from '@/utils/validation';
import { usePostParticipant } from '@/customHooks/index';
import { decrypt } from '@/utils/crypto';
import { NavLink } from 'react-router-dom';
import { constant, modal, table } from '@/utils/constant';
import { supabase } from '../../config/supabase';

function WelcomeParticipants({ session, id }) {
	const [show, setShowModel] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const [results, setResults] = useState([]);
	const addParticipantMutation = usePostParticipant();
	const [isSearchClick, setIsserchClick] = useState(false);
	const [isTrue, setIsTrue] = useState(false);
	const [isSearch, setIsSearch] = useState(0);
	const [options, setOptions] = useState([]);
	const [options1, setOptions1] = useState([]);

	useEffect(() => {
		const hasOpened = sessionStorage.getItem("modalOpened");
		const isNotEmpty = Object.keys(session).length > 0;
		if (!hasOpened && isNotEmpty) {
			setShowModel(!session.is_open_game_link);
			sessionStorage.setItem("modalOpened", "true");
		}
	}, [session, session.is_open_game_link]);

	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
		},
		validationSchema: addParticipantValidate,
		onSubmit: async values => {
			setErrorMsg('');
			setIsTrue(false);
			setIsDisabled(true);
			const addParticipantsData = {
				session_id: Number(decrypt(id)),
				first_name: values.firstName,
				last_name: values.lastName,
				isSearchClick: isSearchClick,
			};
			try {
				const data = await addParticipantMutation.mutateAsync(
					addParticipantsData
				);
				if (data?.error) {
					setIsTrue(data?.isTrue);
					setErrorMsg(data?.error);
					setIsDisabled(false);
				} else if (!data.data) {
					setIsDisabled(false);
					setShowModel(false);
				} else {
					setShowModel(false);
				}
			} catch (error) {}
		},
	});

	const handleSearchChange = async event => {
		setIsserchClick(false);
		const { name, value } = event.target;
		formik.handleChange(event);
		if ((name === 'firstName' || name === 'lastName') && value.length >= 2) {
			setIsSearch(name === 'firstName' ? 1 : 2);
			try {
				return await supabase
					.from('session')
					.select()
					.eq('id', Number(decrypt(id)))
					.then(async session_item => {
						if ([200].includes(session_item.status)) {
							// get session participant list.
							const { data } = await supabase
								.from('participant')
								.select('*')
								.or(`first_name.ilike.%${value}%,last_name.ilike.%${value}%`)
								.eq('session_id', Number(decrypt(id)));
							setResults(data);
						}
					});
			} catch (err) {
				console.error('Error in search: ', err);
			}
		} else {
			setResults([]);
		}
	};

	useEffect(() => {
		if (isSearch === 1) {
			setOptions(
				results && results?.map(user => ({
					value: user.id,
					label: `${user.first_name} ${user.last_name}`,
					first_name: user.first_name,
					last_name: user.last_name,
				}))
			);
		} else if (isSearch === 2) {
			setOptions1(
				results && results?.map(user => ({
					value: user.id,
					label: `${user.first_name} ${user.last_name}`,
					first_name: user.first_name,
					last_name: user.last_name,
				}))
			);
		}
	}, [isSearch, results]);

	return (
		<>
			<Modal
				show={show}
				// onHide={() => setShowModel(false)}
				size='md'
				centered
				// backdrop='static'
			>
				<Modal.Header
					className='bg-secondary'
					// closeButton
					// onClick={() => setShowModel(false)}
				>
					<Modal.Title className='ps-sm-6'>
						{modal?.addWelcomeParticipantModalTitle}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className='py-10'>
					<p className='text-center'>
						{modal?.addWelcomeParticipantText}
						<b> {session?.name}</b>
					</p>
					<p className='mb-5 text-center'>
						{modal?.addWelcomeParticipantText1}
					</p>
					<form
						className='mx-auto form'
						onSubmit={formik.handleSubmit}
					>
						<div>
							<label
								className='form-label'
								htmlFor='firstName'
							>
								{constant?.firstName}
							</label>
							<FormControl
								value={formik.values.firstName}
								onChange={handleSearchChange}
								name='firstName'
								placeholder=''
								autoComplete='off'
							/>
							{options.length > 0 && (
								<ul className='w-full bg-light p-3 list-unstyled'>
									{options.map((option, index) => (
										<li
											className='cursor-pointer'
											onClick={() => {
												formik.setFieldValue('firstName', option.first_name);
												formik.setFieldValue('lastName', option.last_name);
												setIsserchClick(true);
												setResults([]);
											}}
										>
											{option.label}
										</li>
									))}
								</ul>
							)}
							{formik.submitCount > 0 && formik.errors.firstName && (
								<div className='text-danger'>{formik.errors.firstName}</div>
							)}
						</div>
						<div className='mt-6'>
							<label
								className='form-label'
								htmlFor='lastName'
							>
								{constant?.lastName}
							</label>
							<FormControl
								value={formik.values.lastName}
								onChange={handleSearchChange}
								name='lastName'
								placeholder=''
								autoComplete='off'
							/>
							{options1.length > 0 && (
								<ul className='w-full bg-light p-3 list-unstyled'>
									{options1.map((option, index) => (
										<li
											className='cursor-pointer'
											onClick={() => {
												formik.setFieldValue('firstName', option.first_name);
												formik.setFieldValue('lastName', option.last_name);
												setIsserchClick(true);
												setResults([]);
											}}
										>
											{option.label}
										</li>
									))}
								</ul>
							)}
							{formik.submitCount > 0 && formik.errors.lastName && (
								<div className='text-danger'>{formik.errors.lastName}</div>
							)}
						</div>
						<div className='px-2 text-center'>
							<Button
								variant='secondary'
								size='lg'
								className='w-full mt-10 mb-3'
								type='submit'
								disabled={isDisabled}
							>
								{modal?.addWelcomeParticipantButtonText}
							</Button>
							<NavLink
								to={'/'}
								className='text-center welcome-text-decoration'
							>
								{table?.facilitatorLogin}
							</NavLink>
						</div>
					</form>
					{errorMsg &&
						(isTrue ? (
							<div className='text-danger text-center w-100'>
								{`This session is full.`}
								<br />
								{`Please ask the facilitator for assistance.`}
							</div>
						) : (
							<div className='text-danger text-center'>{errorMsg}</div>
						))}
				</Modal.Body>
			</Modal>
		</>
	);
}
export default WelcomeParticipants;
