import { Modal, Button, Row, Col } from "react-bootstrap";
import { modal } from "@/utils/constant";

function OpenGameLinkModal({ hide, show, handleShowGame }) {
  return (
    <>
      <Modal show={show} onHide={hide} size="lg" centered>
        <Modal.Header className="bg-secondary" closeButton>
          <Modal.Title style={{ marginLeft: "45px" }}>
            {modal?.openGameLinkTitalText}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-10 px-lg-14">
          <p className="mb-3" style={{ textAlign: "justify" }}>
            {modal?.openGameLinkBody} <b>{modal?.openGameLinkBreakout}</b>{" "}
            {modal?.openGameLinkBodyText}
          </p>
          <Row className="mt-8">
            <Col lg={6} md={6} className="mx-auto">
              <Button
                variant="secondary"
                className="font-semibold w-full"
                onClick={() => handleShowGame()}
              >
                {modal?.openGameLinkButtonText}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default OpenGameLinkModal;
