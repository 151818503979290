import { supabase } from "../config/supabase";
import moment from "moment";
const statusCode = [200, 201, 204];

export const useFetchSession = async (sessionData) => {
  try {
    if (!sessionData?.id) return;
    const currentDate = new Date().toLocaleString("en-US").split(", ")[0];
    const PAGE_SIZE = process.env.REACT_APP_SESSION_TABLE_PAGE;
    const query = supabase
      .from("session")
      .select(
        "id, name, session_date, no_of_participant, amount, payment_method, is_open_game_link, team_participant (*), facilitator (id, uuid, first_name, last_name, email), co_facilitator (id, uuid, first_name, last_name, email), user_id (id, uuid, first_name, last_name, email), organization_id, is_game_complete, organization_sheet, payment_link, selected_sheet, is_game_pause, game_start_time",
      )
      .eq("organization_id", sessionData.id)
      .is("cancel_session", null);

    if (!sessionData?.isAscending) {
      // past
      query.lte("session_date", currentDate);
      query.eq("is_game_complete", 1);
      query.order("id", { ascending: false });
    }
    if (sessionData?.isAscending) {
      // upcoming
      query.gte("session_date", currentDate);
      query.eq("is_game_complete", 0);
    }
    query.order("session_date", { ascending: sessionData?.isAscending });
    const { data: totalData } = await query;
    query.range(
      sessionData.currentPage * PAGE_SIZE - PAGE_SIZE,
      sessionData?.isAscending
        ? sessionData.currentPage * PAGE_SIZE - 1
        : sessionData.currentPage * PAGE_SIZE,
    );

    const { data } = await query;
    const totalPages = Math.ceil(totalData.length / PAGE_SIZE);
    return { session: data, totalPages };
  } catch (error) {}
};
export const useFetchSingleSession = async (id) => {
  try {
    if (!id) return;
    return supabase
      .from("session")
      .select(
        "id, name, session_date, orientation_video_start, start_date, no_of_participant, amount, payment_method, is_open_game_link, is_game_complete, facilitator (id, uuid, first_name, last_name, email), co_facilitator (id, uuid, first_name, last_name, email), user_id (id, uuid, first_name, last_name, email), organization_id, organization_sheet, payment_link, selected_sheet, is_game_pause, game_start_time",
      )
      .eq("id", id)
      .then(async (item) => {
        if (statusCode.includes(item.status)) {
          const { data } = await supabase
            .from("team_participant")
            .select()
            .eq("session_id", id);

          return {
            session: item.data[0] || {},
            participant: data,
          };
        }
      });
  } catch (error) {}
};

export const useStartGameUpdateStatus = async (id) => {
  try {
    const momentNow = moment().add(3, "seconds");
    if (!id) return;
    const { data, error } = await supabase
      .from("session")
      .update({ start_date: momentNow, time_zone: id.timeZone })
      .match({ id: id.session_id });
    if (error) {
      return error;
    }

    const { error: partError } = await supabase
      .from("team")
      .update({ current_status: 3 })
      .match({ session_id: id.session_id });
    if (partError) {
      return error;
    }

    const { error: teamPartError } = await supabase
      .from("team_participant")
      .update({ current_status: 3 })
      .match({ session_id: id.session_id });
    if (teamPartError) {
      return error;
    }

    return data;
  } catch (error) {}
};

export const useUpdateSessionData = async (sessionData) => {
  try {
    const { data: sess, error: sessionGetError } = await supabase
      .from("session")
      .select("*")
      .eq("id", sessionData?.id);
    const { data: org } = await supabase
      .from("organization")
      .select()
      .eq("id", sessionData?.organization_id);
    if (sessionGetError) {
      return;
    }
    const increase_no_of_participant =
      Number(sessionData?.no_of_participant) - sess[0]?.no_of_participant;
    const decrease_no_of_participant =
      sess[0]?.no_of_participant - Number(sessionData?.no_of_participant);
    const { data: team, error: teamGetError } = await supabase
      .from("team")
      .select()
      .eq("session_id", sessionData?.id)
      .order("team_index", { ascending: true });
    if (teamGetError) {
      return;
    }
    if (increase_no_of_participant > 0) {
      const loop = Math.floor(sessionData?.no_of_participant / 4);
      const needToAdd = loop - team.length;
      // inserts for teams
      const teamInsertPromises = [];

      for (let index = 1; index < needToAdd + 1; index++) {
        teamInsertPromises.push({
          session_id: sessionData?.id,
          team_index: team.length + index,
        });
      }
      if (teamInsertPromises.length > 0) {
        const { data: team_participant, error } = await supabase
          .from("team_participant")
          .select()
          .eq("session_id", sessionData?.id)
          .gte("role_id", 5)
          .order("id", { ascending: false });
        team_participant.forEach(async (element, index) => {
          if (teamInsertPromises.length * 4 > index) {
            await supabase
              .from("team_participant")
              .delete()
              .eq("id", element.id);
          }
        });
      }
      // insert teams
      const { data: teamInsertResults } = await supabase
        .from("team")
        .insert(teamInsertPromises)
        .select("id");

      // inserts for participant
      const participantInsertPromises = [];
      for (let index = 0; index < teamInsertResults.length; index++) {
        const team_id = teamInsertResults[index].id;
        for (let i = 0; i < 4; i++) {
          participantInsertPromises.push({
            role_id: i + 1,
            session_id: sessionData?.id,
            team_id,
          });
        }
      }
      // insert participants
      await Promise.all(participantInsertPromises);
      await supabase.from("team_participant").insert(participantInsertPromises);
    }
    if (decrease_no_of_participant > 0) {
      const loop = Math.floor(sessionData?.no_of_participant / 4);
      const needToRemove = team.length - loop;
      const teamsToRemove =
        needToRemove > 0 ? team.slice(-needToRemove).reverse() : [];
      const { data: teamParticipant } = await supabase
        .from("team_participant")
        .select()
        .eq("session_id", sessionData?.id);
      const { data: participant } = await supabase
        .from("participant")
        .select()
        .eq("session_id", sessionData?.id);

      const RemoveTeamParticipant = async (total = 0) => {
        // Remove team participant only
        const { data: CoteamParticipant } = await supabase
          .from("team_participant")
          .select()
          .eq("session_id", sessionData?.id)
          .gte("role_id", 5)
          .order("participant_id", { ascending: true, nullsFirst: true });
        const { data: teamParticipant } = await supabase
          .from("team_participant")
          .select()
          .eq("session_id", sessionData?.id)
          .lt("role_id", 5)
          .order("team_id", { ascending: false });
        const uniqueIds = [...CoteamParticipant, ...teamParticipant];

        uniqueIds &&
          uniqueIds?.map(async (item, index) => {
            if (
              total > 0
                ? total > index
                : Math.abs(
                    uniqueIds.length - Number(sessionData?.no_of_participant),
                  ) > index
            ) {
              const { error: teamParticipantRemove } = await supabase
                .from("team_participant")
                .delete()
                .eq("id", item.id);
            }
          });
      };
      if (
        teamParticipant.length === participant.length &&
        teamsToRemove.length === 0
      ) {
        const { data: CoteamParticipant } = await supabase
          .from("team_participant")
          .select()
          .eq("session_id", sessionData?.id)
          .gte("role_id", 5)
          .order("participant_id", { ascending: false, nullsFirst: true });
        const { data: teamParticipant } = await supabase
          .from("team_participant")
          .select()
          .eq("session_id", sessionData?.id)
          .lt("role_id", 5)
          .order("team_id", { ascending: false });
        const uniqueIds = [...CoteamParticipant, ...teamParticipant];

        uniqueIds &&
          uniqueIds?.map(async (item, index) => {
            if (decrease_no_of_participant > index) {
              const { error: teamPartiError } = await supabase
                .from("team_participant")
                .delete()
                .eq("id", item.id);
              await supabase
                .from("participant")
                .delete()
                .eq("id", item.participant_id);
            }
          });
      }
      if (
        teamParticipant.length > participant.length &&
        teamsToRemove.length === 0
      ) {
        // Remove team participant only
        console.log("Remove team participant");
        RemoveTeamParticipant(0);
      }
      if (
        teamParticipant.length < participant.length &&
        teamsToRemove.length === 0
      ) {
        // Remove participant only
        console.log("Remove participant");
        const participant_data = participant.reverse();
        const randomIds = participant_data.map((obj) => obj.id);
        const { data: teamParticipant } = await supabase
          .from("team_participant")
          .select("participant_id")
          .eq("session_id", sessionData?.id)
          .not("participant_id", "is", null);
        const teamParticipantIds =
          teamParticipant?.map((participant) => participant.participant_id) ||
          [];
        const diffFromArray1 = randomIds.filter(
          (id) => !teamParticipantIds.includes(id),
        );
        const diffFromArray2 = teamParticipantIds.filter(
          (id) => !randomIds.includes(id),
        );
        const uniqueIds = [...diffFromArray1, ...diffFromArray2];
        uniqueIds.map(async (id, index) => {
          if(decrease_no_of_participant > index){
            await supabase.from("participant").delete().eq("id", id);
          }
        });
      }
      const modifyArray = (arr, number) => {
        if (number === 1) {
          arr.pop();
        } else if (number >= 2) {
          arr.splice(-number, number);
        }
        return arr;
      };
      try {
        let totalRemoveMember = 0;
        const deleteOperations = teamsToRemove.map(async (element, index) => {
          try {
            const { data: teamParticipants } = await supabase
              .from("team_participant")
              .select()
              .eq("team_id", element?.id);

            // const { data: participant } = await supabase
            // 	.from('participant')
            // 	.select()
            // 	.eq('session_id', sessionData?.id);
            totalRemoveMember += teamParticipants.length;
            if (teamParticipants?.length) {
              let participantsToDelete = [];
              if (participant.length > Number(sessionData?.no_of_participant)) {
                participantsToDelete = teamParticipants
                  .slice(
                    0,
                    teamParticipant.length === participant.length
                      ? decrease_no_of_participant
                      : Math.abs(
                          participant.length - sessionData?.no_of_participant,
                        ) + index,
                  )
                  .map((item) => item.participant_id)
                  .filter((participantId) => participantId !== null);
                await supabase
                  .from("team_participant")
                  .update({
                    participant_id: null,
                  })
                  .in("participant_id", participantsToDelete);
              }
              const teamParticipantIds = teamParticipants
                .map((item) => item.id)
                .filter((teamParticipantId) => teamParticipantId !== null);

              // Bulk delete participants and team participants
              const { error: deleteTeamParticipantError } = await supabase
                .from("team_participant")
                .delete()
                .in("id", teamParticipantIds);
              if (
                totalRemoveMember > decrease_no_of_participant &&
                teamsToRemove.length > 1 &&
                index + 1 === teamsToRemove.length
              ) {
                participantsToDelete = await modifyArray(
                  participantsToDelete,
                  Math.abs(totalRemoveMember - decrease_no_of_participant),
                );
              }
              if (
                participantsToDelete.length > 0 &&
                participant.length !== Number(sessionData?.no_of_participant)
              ) {
                const { error: deleteParticipantError } = await supabase
                  .from("participant")
                  .delete()
                  .in("id", participantsToDelete);
                if (deleteParticipantError) {
                  throw deleteParticipantError;
                }
              }
              if (deleteTeamParticipantError) {
                throw deleteTeamParticipantError;
              }
            }
            if (
              totalRemoveMember < decrease_no_of_participant &&
              teamsToRemove.length === index + 1
            ) {
              console.log("aslkdjlasdkajsdklas");
              const { data: teamParticipants } = await supabase
                .from("team_participant")
                .select()
                .eq("session_id", sessionData.id)
                .gte("role_id", 5)
                .order("id", { ascending: false })
                .limit(
                  Math.abs(decrease_no_of_participant - totalRemoveMember),
                );
              const { data: participant } = await supabase
                .from("participant")
                .select()
                .eq("session_id", sessionData?.id)
                .order("id", { ascending: false });
              if (teamParticipants.length > 0) {
                teamParticipants &&
                  teamParticipants.map(async (item, index) => {
                    await supabase
                      .from("team_participant")
                      .delete()
                      .eq("id", item?.id);
                    if (
                      participant.length !==
                      Number(sessionData?.no_of_participant)
                    ) {
                      await supabase
                        .from("participant")
                        .delete()
                        .eq("id", item.participant_id);
                    }
                  });
              } else {
                if (
                  participant.length !== Number(sessionData?.no_of_participant)
                ) {
                  participant &&
                    participant.forEach(async (obj, index) => {
                      if (
                        index <
                        Math.abs(
                          participant.length -
                            Number(sessionData?.no_of_participant),
                        )
                      ) {
                        await supabase
                          .from("participant")
                          .delete()
                          .eq("id", obj.id);
                      }
                    });
                }
              }
            }
            // if (
            // 	Math.abs(
            // 		teamParticipant?.length - sessionData.no_of_participant
            // 	) - totalRemoveMember > 0 &&
            // 	teamsToRemove.length === index + 1
            // ) {
            // 	RemoveTeamParticipant(
            // 		Math.abs(
            // 			teamParticipant?.length - sessionData.no_of_participant
            // 		) - totalRemoveMember
            // 	);
            // }
            // Delete the team
            const { error: teamError } = await supabase
              .from("team")
              .delete()
              .eq("id", element.id);

            if (teamError) {
              console.error("teamError", teamError);
              throw teamError;
            }
          } catch (error) {
            console.error("deleteOperationError", error);
            throw error;
          }
        });
        await Promise.all(deleteOperations);
      } catch (error) {}
    }
    const { data: session, error: sessionError } = await supabase
      .from("session")
      .update({
        ...sessionData,
      })
      .eq("id", sessionData?.id)
      .select();
    await supabase
      .from("organization")
      .update({
        available_seat:
          sess[0]?.organization_sheet < sessionData?.organization_sheet
            ? org[0]?.available_seat -
              Math.abs(
                sess[0]?.organization_sheet - sessionData?.organization_sheet,
              )
            : org[0]?.available_seat +
              Math.abs(
                sess[0]?.organization_sheet - sessionData?.organization_sheet,
              ),
      })
      .eq("id", sessionData?.organization_id);
    if (sessionError) {
      return;
    }
    return { session: session };
  } catch (error) {}
};

export const useCancelSession = async (data) => {
  try {
    if (!data.id) return;
    const { data: session, error } = await supabase
      .from("session")
      .update({ cancel_session: new Date() })
      .match({ id: data.id });
    await supabase
      .from("organization")
      .update({ available_seat: data?.total_sheet })
      .match({ id: data.organization_id });
    if (error) {
      return error;
    }
    return session;
  } catch (error) {}
};
export const useAddSession = async (sessionData) => {
  const userId = localStorage.getItem("userId");
  try {
    let selected_sheet = 0;
    let organization_sheet = 0;
    let session_organization_sheet = 0;

    if (sessionData?.is_check_mark) {
      const { data: org } = await supabase
        .from("organization")
        .select()
        .eq("id", sessionData?.organization_id);

      const available_seat = org[0]?.available_seat;
      const no_of_participant = sessionData?.no_of_participant;

      if (available_seat >= no_of_participant) {
        const total_seat = available_seat - no_of_participant;
        selected_sheet = 0;
        organization_sheet = total_seat;
        session_organization_sheet = no_of_participant;
      } else {
        const total_seat = no_of_participant - available_seat;
        selected_sheet = total_seat;
        organization_sheet = 0;
        session_organization_sheet = available_seat;
      }

      // Update organization's available_seat
      await supabase
        .from("organization")
        .update({
          available_seat: organization_sheet,
        })
        .eq("id", sessionData?.organization_id);
    } else {
      selected_sheet = sessionData?.no_of_participant;
    }

    // Create session
    delete sessionData?.is_check_mark;
    const { data: session, error: sessionError } = await supabase
      .from("session")
      .insert({
        ...sessionData,
        user_id: userId,
        selected_sheet,
        organization_sheet: session_organization_sheet,
      })
      .select();
    if (sessionError) {
      return;
    }
    if (session.length > 0) {
      const loop = Math.floor(sessionData?.no_of_participant / 4);

      // inserts for teams
      const teamInsertPromises = [];

      for (let index = 1; index < loop + 1; index++) {
        teamInsertPromises.push({
          session_id: session[0].id,
          team_index: index,
        });
      }
      // insert teams
      const { data: teamInsertResults } = await supabase
        .from("team")
        .insert(teamInsertPromises)
        .select("id");

      // inserts for participant
      const participantInsertPromises = [];
      for (let index = 0; index < teamInsertResults.length; index++) {
        const team_id = teamInsertResults[index].id;
        for (let i = 0; i < 4; i++) {
          participantInsertPromises.push({
            role_id: i + 1,
            session_id: session[0].id,
            team_id,
          });
        }
      }
      // insert participants
      await Promise.all(participantInsertPromises);
      await supabase.from("team_participant").insert(participantInsertPromises);
    }
    return { session: session };
  } catch (error) {}
};

export const updateSession = async () => {
  try {
    const { data } = await supabase
      .from("session")
      .select("id", { column: "array_agg" })
      .lt("session_date", moment(new Date()).format("YYYY-MM-DD"))
      .eq("is_game_complete", 0);

    const promises = data.map(async (update) => {
      await supabase
        .from("session")
        .update({ is_game_complete: 1 })
        .eq("id", update.id);
    });

    // Wait for all updates to complete
    await Promise.all(promises);
    return data;
  } catch (error) {
    console.log("error", error);
  }
};
