import { React } from "react";
import { OverlayTrigger, Popover, Table, Tooltip } from "react-bootstrap";
import { BsQuestionCircle, BsListUl } from "react-icons/bs";
import CountdownTimer from "./gameTime";
import { blendedTeamCost, blendedTeamQualityError } from "@/utils/activity";
import { constant, table } from "@/utils/constant";
import { FaBell } from 'react-icons/fa';

function GroupTable({
  allTeam,
  countQualityErrors,
  isGameComplete,
  isGameStart,
  sessionData,
  teamData,
}) {
  const commonClassName = !isGameComplete ? "text-light-gray" : "";
  const renderTooltip = (props) => (
    <Tooltip className="tooltip-light" id="button-tooltip" {...props}>
      {constant?.groupBehaviorTool}
    </Tooltip>
  );
  return (
    <>
      <div className="table-responsive group-table">
        <Table bordered className="position-relative">
          <thead>
            <tr className="text-center">
              <th className="text-start" rowSpan={2}>
                {constant.teams}
              </th>
              <th rowSpan={2}>
                {table?.time} <br />
                {table?.remaining}
              </th>
              <th rowSpan={2}>
                {table?.simulated} <br />
                {table?.hour}
              </th>
              <th colSpan={3} className="py-1">
                {table?.behaviorIndicators}
                <OverlayTrigger
                  placement="bottom-start"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <span>
                    {" "}
                    <BsQuestionCircle className="ms-2" />{" "}
                  </span>
                </OverlayTrigger>
              </th>
              <th rowSpan={2}>{table?.activity}</th>
              <th rowSpan={2}>
                {table?.quality} <br /> {table?.loss}
              </th>
              <th rowSpan={2}>{table?.cost}</th>
              <th rowSpan={2}>
                {table?.blended} <br /> {table?.score}
              </th>
            </tr>
            <tr className="border-bottom border-2 text-center">
              <th className="py-1">{table?.collaborative}</th>
              <th className="py-1">{table?.innovative}</th>
              <th className="py-1">{table?.dataDriven}</th>
            </tr>
          </thead>
          <tbody className="position-relative text-center">
            {allTeam?.map((item, index) => {
              const data = countQualityErrors(
                item?.games,
                item.current_round,
                item?.game_complete_status,
                index
              );
              const commonActivity = [
                {
                  name: constant?.ambulanceDiversions,
                  value: data?.activity?.ambulanceDiversionsActivity,
                },
                {
                  name: constant?.patientsInWaiting,
                  value: data?.activity?.patientsWaitingActivity,
                },
                {
                  name: constant?.arrivalsWaiting,
                  value: data?.activity?.arrivalsWaitingActivity,
                },
                {
                  name: constant?.requestsWaiting,
                  value: data?.activity?.requestWaitingActivity,
                },
                {
                  name: constant?.extraStaff,
                  value: data?.activity?.extraStaffActivity,
                },
              ];
              const popover = (
                <Popover
                  id="popover-basic"
                  className="w-full"
                  style={{ maxWidth: "237px" }}
                >
                  <Popover.Body>
                    {commonActivity?.map((item, index) => (
                      <div key={index} className="d-flex py-1">
                        <span>{item.name}</span>
                        <span className="ms-auto">{item.value}</span>
                      </div>
                    ))}
                  </Popover.Body>
                </Popover>
              );
              const blendedScore =
                data.teamQualityError / blendedTeamQualityError +
                data.teamCost / blendedTeamCost / 2;
                const array = teamData && teamData[index]?.participant;
                const hasHelpCallForFacilitator = array?.some(
                  item => item.is_help_call_for_facilitator
                );

              return (
                <tr key={index}>
                  <td
										className={`text-start ${
											hasHelpCallForFacilitator ? 'text-parpal bg-parpal-pulse' : ''
										}`}
									>
										{hasHelpCallForFacilitator && (
											<FaBell
												className='circle pulse me-2 mb-1'
												style={{ height: '17px', width: '15px' }}
											/>
										)}
										Team {item?.team_index}:{' '}
										{item?.name && item?.name.length > 25
											? `${item?.name.slice(0, 25)}...`
											: item?.name}
									</td>
                  <td>
                    <CountdownTimer
                      time={sessionData.game_start_time}
                      complete={item?.is_game_complete === 1 ? true : false}
                      timeToShow={3600}
                      teamData={item}
                      sessionData={sessionData}
                    />
                  </td>
                  <td>
                    {isGameStart
                      ? `${
                          item?.current_round === 13
                            ? "Midnight"
                            : item?.current_round > 13
                              ? "Saturday"
                              : "Fri"
                        } ${
                          item
                            ? [0, 1].includes(item?.current_round)
                              ? "Noon"
                              : item?.current_round === 13
                                ? ""
                                : item?.current_round -
                                  (item?.current_round >= 14 ? 13 : 1) +
                                  (item?.current_round >= 13 ? " AM" : " PM")
                            : ""
                        }`
                      : constant?.notStart}
                  </td>
                  {index === 0 && (
                    <td
                      className="text-center"
                      rowSpan={allTeam?.length}
                      colSpan="3"
                    >
                      {table?.comingSoon}
                    </td>
                  )}
                  <td className="text-center position-relative">
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom-start"
                      overlay={popover}
                      rootClose={true}
                    >
                      <span>
                        <BsListUl className="text-lg" />
                      </span>
                    </OverlayTrigger>
                  </td>
                  <td className={commonClassName}>
                    {data.teamQualityError.toLocaleString("en-en")}
                  </td>
                  <td className={commonClassName}>
                    ${data.teamCost.toLocaleString("en-en")}
                  </td>
                  <td className={commonClassName}>{blendedScore.toFixed(2)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
}
export default GroupTable;
