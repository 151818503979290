import { React } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  FormControl,
} from "react-bootstrap";
import { useFormik } from "formik";
import { Logo } from "@/assets/images/index";
import { Link } from "react-router-dom";
import { resetPassValidate } from "@/utils/validation";
import { supabase } from "@/config/supabase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { constant } from "@/utils/constant";

const ForgotPassword = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: resetPassValidate,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (values.email) {
          const data = await supabase.auth.resetPasswordForEmail(values.email);
          if (data?.error) {
            return;
          } else {
            toast("Please check your inbox", {
              className: "foo-bar",
            });
          }
        }
      } catch (error) {
        resetForm({
          values: {
            ...values,
            email: "",
          },
        });
      }
    },
  });

  const handleSignInClick = (e) => {
    e.preventDefault();
    formik.handleSubmit();
  };
  return (
    <section className="bg-primary min-h-screen hstack ">
      <Container>
        <Row>
          <Col lg={4} md={6} className="mx-auto">
            <div className="text-center">
              <Image src={Logo} className="h-10" />
              <p className="text-light mt-4">
                {constant?.virtual}
                <span className="font-bold ms-1">{constant?.fridayNight}</span>
              </p>
            </div>
            <form className="mt-10 form mx-auto">
              <div className="mt-6">
                <label className="form-label text-light" htmlFor="email">
                  {constant?.email}
                </label>
                <FormControl
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.submitCount > 0 && formik.errors.email && (
                  <div className="text-danger">{formik.errors.email}</div>
                )}
              </div>
              <div className="px-2">
                <Button
                  className="w-full mt-10 mb-3 btn btn-secondary btn-lg"
                  type="submit"
                  onClick={(e) => handleSignInClick(e)}
                >
                  Reset Password
                </Button>
                <Link
                  to={`/login`}
                  className="text-light hstack justify-content-center text-no-decoration"
                >
                  {constant?.login}
                </Link>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </section>
  );
};
export default ForgotPassword;
