import { supabase } from "../config/supabase";
import React, { useState } from "react";
import { Container, Button } from "react-bootstrap";
import { BsFillCheckCircleFill, BsXCircle } from "react-icons/bs";
import { connection } from "@/utils/constant";
import CommonLayout from "./commonLayout";

const Connection = () => {
  const [btnDisable, setBtnDisable] = useState(false);
  const [btnText, setBtnText] = useState("Start the Test");
  const [status, setStatus] = useState("");
  const [networtData, setNetwortData] = useState([]);
  const [speed, setSpeed] = useState([]);

  const measureDownloadSpeed = async () => {
    const startTime = performance.now();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/download-speed`,
        { method: "GET" },
      );
      if (!response.ok) throw new Error("Network response was not ok");

      const endTime = performance.now();

      const dataSize =
        response.headers.get("Content-Length") || 5 * 1024 * 1024; // in bytes
      const duration = (endTime - startTime) / 1000; // Convert to seconds

      if (duration === 0) return 0;
      const speedMbps = (dataSize * 8) / (1024 * 1024) / duration;
      return speedMbps.toFixed(2);
    } catch (error) {
      return "Error";
    }
  };

  const measureUploadSpeed = async () => {
    const data = "x".repeat(1 * 1024 * 1024); // 1 MB of data for testing
    const startTime = new Date().getTime();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/upload-speed`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/octet-stream",
            "Content-Length": data.length,
          },
          body: data,
        },
      );
      if (!response.ok) throw new Error("Network response was not ok");
      const endTime = new Date().getTime();

      const duration = (endTime - startTime) / 1000; // Convert to seconds
      const speedMbps = (data.length * 8) / (1024 * 1024) / duration;
      return speedMbps.toFixed(2);
    } catch (error) {
      return "Error";
    }
  };

  const checkServerStatus = async () => {
    try {
      const { data, error } = await supabase
        .from("participant")
        .select("id")
        .limit(1);
      if (error) {
        return "Failed";
      }
      if (data) {
        return "Successful";
      }
    } catch (error) {
      return "Failed";
    }
    return "Intermittent";
  };

  const networkResponsiveness = async () => {
    const url = `${process.env.REACT_APP_API_URL}/status`;
    const numPings = 5,
      numRequests = 10;
    let totalTime = 0,
      successCount = 0;

    for (let i = 0; i < numPings; i++) {
      const startTime = new Date().getTime();
      try {
        await fetch(url, { method: "HEAD" });
        const endTime = new Date().getTime();
        totalTime += endTime - startTime;
      } catch (error) {
        console.error("Ping error:", error);
      }
    }
    for (let i = 0; i < numRequests; i++) {
      try {
        const response = await fetch(url, { method: "HEAD" });
        if (response.ok) {
          successCount++;
        }
      } catch (error) {
        console.error("Packet loss simulation:", error);
      }
    }

    const averagePing = totalTime / numPings;
    const packetLoss = ((numRequests - successCount) / numRequests) * 100;

    return [averagePing, packetLoss];
  };

  const handleClick = async () => {
    setBtnDisable(true);
    setBtnText("Test in Progress");
    const network_data = await networkResponsiveness();
    const download_speed = await measureDownloadSpeed();
    const upload_speed = await measureUploadSpeed();
    setSpeed([Number(download_speed), Number(upload_speed)]);
    const isSuccess = await checkServerStatus();
    if (isSuccess === "Successful") {
      setStatus("Server Connection: Successful");
    } else if (isSuccess === "Failed") {
      setStatus("Server Connection: Failed");
    } else {
      setStatus("Server Connection: Intermittent");
    }
    setNetwortData(network_data);
    setBtnText("Test Again");
    setBtnDisable(false);
  };

  return (
    <CommonLayout>
      <Container className="mt-8">
        <div className="mb-8 vstack gap-8">
          <p className="text-md">
            {
              "Use this tool to check your network readiness and ensure a seamless vFNER experience."
            }
          </p>
          <Button
            disabled={btnDisable}
            variant={"secondary"}
            className={`py-3 me-3 mt-2 my-3 px-14 text-common text-futura-medium w-max`}
            onClick={() => handleClick()}
          >
            {btnText}
          </Button>
          <div>
            <h5 className="text-lg font-bolder">{"Connection Speed"}</h5>
            <p className="text-sm mt-2 mb-3">
              {btnText === "Start the Test" && speed?.length === 0
                ? connection?.readyToTest
                : btnText === "Test in Progress" && speed?.length === 0
                  ? connection?.connectionSpeedText
                  : `Download Speed: ${speed[0]} Mbps | Upload Speed: ${speed[1]} Mbps`}
            </p>
            {speed[0] >= 100 ? ( //&& speed[1] >= 100
              <span className="pe-2 lh-none text-success font-bolder text-sm hstack">
                <BsFillCheckCircleFill
                  className="text-success me-2"
                  size={18}
                />
                {connection?.connectionSpeedSuccessText}
              </span>
            ) : speed[0] > 25 && speed[0] < 100 ? ( //&& speed[1] > 25 && speed[1] < 100
              <span className="pe-2 lh-none text-yellow-orange font-bolder text-sm hstack">
                <BsFillCheckCircleFill
                  className="text-yellow-orange me-2"
                  size={18}
                />
                {connection?.connectionSpeedWarningText}
              </span>
            ) : speed[0] < 25 ? ( //&& speed[1] < 25
              <span className="pe-2 lh-none text-danger font-bolder text-sm hstack">
                <BsXCircle className="text-danger me-2" size={18} />
                {connection?.connectionSpeedErrorText}
              </span>
            ) : (
              ""
            )}
          </div>

          <div>
            <h5 className="text-lg font-bolder">{"Network Responsiveness"}</h5>
            <p className="text-sm mt-2 mb-3">
              {btnText === "Start the Test" && networtData.length === 0
                ? connection?.readyToTest
                : btnText === "Test in Progress" && networtData.length === 0
                  ? connection?.networkResponsivenessText
                  : `Ping: ${networtData[0]} ms | Packet Loss: ${networtData[1]}%`}
            </p>
            {networtData[0] < 125 ? ( //&& networtData[1] < 20
              <span className="pe-2 lh-none text-success font-bolder text-sm hstack">
                <BsFillCheckCircleFill
                  className="text-success me-2"
                  size={18}
                />
                {connection?.networkResponsivenessSuccessText}
              </span>
            ) : networtData[0] >= 125 && networtData[0] < 300 ? (
              <span className="pe-2 lh-none text-yellow-orange font-bolder text-sm hstack">
                <BsFillCheckCircleFill
                  className="text-yellow-orange me-2"
                  size={18}
                />
                {connection?.networkResponsivenessWarningText}
              </span>
            ) : networtData[0] >= 300 ? (
              <span className="pe-2 lh-none text-danger font-bolder text-sm hstack">
                <BsXCircle className="text-danger me-2" size={18} />
                {connection?.networkResponsivenessErrorText}
              </span>
            ) : (
              ""
            )}
          </div>

          <div>
            <h5 className="text-lg font-bolder">{"Server Connection"}</h5>
            <p className="text-sm mt-2 mb-3">
              {btnText === "Start the Test" && status === ""
                ? connection?.readyToTest
                : btnText === "Test in Progress" && status === ""
                  ? connection?.serverConnectionText
                  : status}
            </p>
            {status === "Server Connection: Successful" ? (
              <span className="pe-2 lh-none text-success font-bolder text-sm hstack">
                <BsFillCheckCircleFill
                  className="text-success me-2"
                  size={18}
                />
                {connection?.serverConnectionSuccessText}
              </span>
            ) : status === "Server Connection: Intermittent" ? (
              <span className="pe-2 lh-none text-yellow-orange font-bolder text-sm hstack">
                <BsFillCheckCircleFill
                  className="text-yellow-orange me-2"
                  size={18}
                />
                {connection?.serverConnectionWarningText}
              </span>
            ) : status === "Server Connection: Failed" ? (
              <span className="pe-2 lh-none text-danger font-bolder text-sm hstack">
                <BsXCircle className="text-danger me-2" size={18} />
                {connection?.serverConnectionErrorText}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </Container>
    </CommonLayout>
  );
};
export default Connection;
